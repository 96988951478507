isa-modal {
  --isa-modal-gap: 24px;
  --isa-modal-header-gap: var(--isa-modal-gap);
  --isa-modal-header-background-color: var(--isa-color-neutral-95);
  --isa-modal-background-color: var(--isa-color-neutral-95);

  .isa-modal__header {
    justify-content: flex-start !important;
    isa-button {
      right: calc(var(--isa-modal-header-gap) / 2) !important;
    }
  }
}
