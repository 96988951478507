@mixin safe-area($direction, $value: 0px, $use-padding: true) {
  $property: if($use-padding, padding-#{$direction}, $direction);
  #{$property}: #{$value} !important;
  #{$property}: calc(constant(safe-area-inset-#{$direction}) + #{$value}) !important;
  #{$property}: calc(env(safe-area-inset-#{$direction}) + #{$value}) !important;
}

@mixin supportsColorMix() {
  @supports (background-color: color-mix(in srgb, red 50%, blue)) {
    @content;
  }
}

@function getFallbackColorMix() {
  @return #542759;
}
