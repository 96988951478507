@use 'libs/components/styles';
@use 'typography';
@use 'modal';
@use 'image-loader';
@use 'mat-sort';
@use 'table';
@use 'list-item';

@import url('https://fonts.googleapis.com/css2?family=Cormorant+Garamond:wght@500;700&display=swap');

:root {
  --mtm-shell-main-gap: var(--isa-gap-xl);
  --mtm-shell-nav-width: 88px;
  --mtm-shell-header-min-height: 88px;
}

a,
a:visited,
a:focus,
a:active {
  text-decoration: none;
  color: inherit;
}

a {
  color: var(--isa-color-primary-50);
  text-decoration: underline;
  font-weight: var(--isa-font-weight-bold) !important;
  cursor: pointer;
}

html {
  touch-action: manipulation;
  background-color: var(--isa-color-neutral-95);

  &.cdk-global-scrollblock:has(mtm-auth-login) {
    top: 0 !important;
    left: 0 !important;
  }
}

.danger {
  color: var(--isa-color-danger-50);
}

@media screen and (orientation: landscape) {
  .mtm-root__force-landscape {
    display: none !important;
  }
}

//@keyframes fade-in {
//  from { opacity: 0; }
//}
//
//@keyframes fade-out {
//  to { opacity: 0; }
//}
//
//::view-transition-old(shell-header-title) {
//  height: 32px !important;
//}
//
//::view-transition-new(shell-header-title) {
//  height: 32px !important;
//}

//::view-transition-old(shell-nav-0),
//::view-transition-old(shell-nav-1),
//::view-transition-old(shell-nav-2),
//::view-transition-old(shell-nav-3),
//::view-transition-old(shell-nav-4),
//::view-transition-old(shell-nav-5),
//::view-transition-old(shell-nav-6),
//::view-transition-new(shell-nav-0),
//::view-transition-new(shell-nav-1),
//::view-transition-new(shell-nav-2),
//::view-transition-new(shell-nav-3),
//::view-transition-new(shell-nav-4),
//::view-transition-new(shell-nav-5),
//::view-transition-new(shell-nav-6) {
//  animation-duration: 0s;
//}

//body {
//  min-width: 1180px; // iPad Air 2 2048 x 1536 px
//}
