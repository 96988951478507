.cdk-column-date {
  flex: 0 0 110px;
  white-space: nowrap;
  font-weight: var(--isa-font-weight-bold) !important;
}

.cdk-column-store {
  flex: 0 0 100px;
  font-weight: var(--isa-font-weight-bold) !important;
  --isa-table-row-font-color: var(--isa-color-neutral-20);
}

.cdk-column-total {
  flex: 0 0 140px;
  justify-content: flex-end;
  font-weight: var(--isa-font-weight-bold) !important;
  white-space: nowrap;
}

.cdk-column-id, .cdk-column-externalReferenceId {
  --isa-table-row-font-color: var(--isa-color-neutral-20);
  font-weight: var(--isa-font-weight-bold) !important;
  flex: 0 0 150px;

  & > span {
    flex: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    width: 100%;
  }
}
