.isa-price-hidden {
  filter: blur(4px);
  position: relative;
  background-color: inherit;

  &:after {
    position: absolute;
    content: '0000,00 €';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background-color: inherit;
    white-space: nowrap;
  }
}
