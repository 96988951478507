.list-item--even,
.list-item--odd {
  position: relative;
  --list-item-even-odd-border-radius: 10px;

  mat-cell {
    z-index: 1;
    background-color: transparent !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: var(--isa-gap-sm);
    width: calc(100% - (var(--isa-gap-sm) * 2));
    height: 100%;
    z-index: 0;
  }

  &-first:before,
  &-last:before {
    height: calc(100% - var(--isa-gap-sm));
  }

  &-first:before {
    border-top-left-radius: var(--list-item-even-odd-border-radius);
    border-top-right-radius: var(--list-item-even-odd-border-radius);
    bottom: 0;
    top: auto;
  }

  &-last:before {
    border-bottom-left-radius: var(--list-item-even-odd-border-radius);
    border-bottom-right-radius: var(--list-item-even-odd-border-radius);
  }
}

.list-item--even-first.list-item--even-last:before,
.list-item--odd-first.list-item--odd-last:before {
  height: calc(100% - calc(var(--isa-gap-sm) * 2));
  bottom: auto;
}
