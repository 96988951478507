%typography-h1 {
  font-family: var(--isa-typography-primary-font-family);
  font-weight: var(--isa-typography-h1-font-weight);
  font-size: var(--isa-typography-h1-font-size);
  line-height: var(--isa-typography-h1-font-size);
  color: var(--isa-color-primary-50);
}

%typography-h2 {
  font-family: var(--isa-typography-primary-font-family);
  font-weight: var(--isa-typography-h2-font-weight);
  font-size: var(--isa-typography-h2-font-size);
  line-height: var(--isa-typography-h2-font-size);
  color: var(--isa-color-primary-50);
}

%typography-h3 {
  font-family: var(--isa-typography-secondary-font-family);
  font-weight: var(--isa-typography-h3-font-weight);
  font-size: var(--isa-typography-h3-font-size);
  line-height: var(--isa-typography-h3-font-size);
  color: var(--isa-color-primary-50);
}

%typography-h4 {
  font-family: var(--isa-typography-secondary-font-family);
  font-weight: var(--isa-typography-h4-font-weight);
  font-size: var(--isa-typography-h4-font-size);
  line-height: var(--isa-typography-h4-font-size);
}

%typography-body {
  font-family: var(--isa-typography-secondary-font-family);
  font-weight: var(--isa-typography-body-font-weight);
  font-size: var(--isa-typography-body-font-size);
  line-height: var(--isa-typography-body-font-size);
}

%typography-label {
  font-family: var(--isa-typography-primary-font-family);
  font-weight: var(--i--isa-typography-label-font-weight);
  font-size: var(--isa-typography-label-font-size);
  line-height: var(--isa-typography-label-font-size);
}

%typography-cta {
  font-family: var(--isa-typography-secondary-font-family);
  font-weight: var(---isa-typography-cta-font-weight);
  font-size: var(--isa-typography-cta-font-size);
  line-height: var(--isa-typography-cta-font-size);
}

%typography-small {
  font-family: var(--isa-typography-secondary-font-family);
  font-weight: var(--isa-typography-small-font-weight);
  font-size: var(--isa-typography-small-font-size);
  line-height: var(--isa-typography-small-font-size);
}
