@use 'typography.abstract';

:root {
  --isa-typography-primary-font-family: 'Cormorant Garamond', serif;
  --isa-typography-secondary-font-family: 'Helvetica', sans-serif;
  --isa-typography-h1-font-size: 32px;
  --isa-typography-h1-font-weight: 500;
  --isa-typography-h2-font-size: 24px;
  --isa-typography-h2-font-weight: 500;
  --isa-typography-h3-font-size: 21px;
  --isa-typography-h3-font-weight: 700;
  --isa-typography-h4-font-size: 18px;
  --isa-typography-h4-font-weight: 700;
  --isa-typography-body-font-size: 18px;
  --isa-typography-body-font-weight: 500;
  --isa-typography-label-font-size: 18px;
  --isa-typography-label-font-weight: 500;
  --isa-typography-cta-font-size: 18px;
  --isa-typography-cta-font-weight: 700;
  --isa-typography-small-font-size: 14px;
  --isa-typography-small-font-weight: 500;

  --isa-font-size-md: var(--isa-typography-body-font-size);
  --isa-form-interaction-font-size: var(--isa-typography-body-font-size);
  --isa-form-interaction-font-weight: var(--isa-typography-body-font-weight);
  --isa-button-font-size: var(--isa-typography-cta-font-size);
  --isa-button-font-weight: var(--isa-typography-cta-font-weight);

  isa-label {
    --isa-label-font-size: 14px;
  }

  isa-input-counter {
    --isa-input-counter-font-weight: var(--isa-typography-cta-font-weight);
  }

  isa-table {
    --isa-table-header-font-size: 12px;
    --isa-table-paginator-font-size: 12px;
  }

  mtm-shell nav isa-button,
  mtm-shell-admin nav isa-button {
    --isa-button-font-size: 10px;
  }

  isa-category-card {
    --isa-category-card-figcaption-font-family: var(--isa-typography-primary-font-family);
  }

  isa-modal {
    --isa-modal-header-color: var(--isa-color-primary-50);
    --isa-modal-header-font-size: var(--isa-typography-h3-font-size);
    --isa-modal-header-font-weight: var(--isa-typography-h3-font-weight);
    --isa-modal-header-line-height: var(--isa-typography-h3-font-size);
  }

  isa-input-money {
    --isa-input-money-font-size: 27px;
    --isa-input-money-font-weight: 700;
  }

  mtm-composition-summary {
    --mtm-composition-summary-price-tip-font-size: 12px;
    --mtm-composition-summary-completed-title-font-weight: 700;
  }

  mtm-user-config-modal {
    .typography-label {
      color: var(--isa-color-primary-50);
      --isa-typography-label-font-size: 26px;
    }
  }

  isa-session-card {
    --isa-session-card-info-line-height: 20px;
    --isa-session-card-quantity-font-size: 12px;
    --isa-session-card-customer-font-size: 26px;
    --isa-session-card-active-banner-font-size: 14px;
  }

  isa-section-empty {
    --isa-section-empty-font-family: var(--isa-typography-primary-font-family);
    --isa-section-empty-font-size: var(--isa-typography-h1-font-size);
    --isa-section-empty-line-height: var(--isa-typography-h1-font-size);
    --isa-section-empty-font-weight: var(--isa-typography-h1-font-weight);
  }

  .mtm-customer-search__name-description {
    font-size: var(--isa-typography-small-font-size);
  }

  .mtm-shell__header-customer {
    small {
      font-size: 14px;
    }

    a {
      font-size: 16px;
      font-weight: normal !important;
    }
  }
}

h1,
.typography-h1 {
  @extend %typography-h1;
}

h2,
.typography-h2 {
  @extend %typography-h2;
}

h3,
.typography-h3 {
  @extend %typography-h3;
}

h4,
.typography-h4 {
  @extend %typography-h4;
}

.typography-body {
  @extend %typography-body;
}

.typography-label {
  @extend %typography-label;
}

.typography-cta {
  @extend %typography-cta;
}

body {
  @extend %typography-body;
}

b,
strong {
  font-weight: 700;
}

small {
  @extend %typography-small;
}

u {
  text-decoration: underline;
}
