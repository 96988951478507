.isa-image-loader--error {
  object-fit: scale-down !important;
  height: 100%;
}

isa-category-card {
  :has(.isa-image-loader--error) {
    background-color: transparent;
  }
}

isa-material-card {
  :has(.isa-image-loader--error) {
    --isa-card-background-color: var(--isa-color-neutral-20);
  }

  :has(.isa-image-loader--loading) {
    .isa-image-loader--loading {
      opacity: 0.4;
      filter: grayscale(1);
      background: black;
    }
  }
}
