@use '@angular/material' as mat;
@use 'shared';
@use 'price-hidable/src/lib/price-hidable';

:root {
  --isa-font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI Variable', 'Segoe UI', system-ui, ui-sans-serif, Helvetica, Arial,
    sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';

  --isa-font-size-xxs: 8px;
  --isa-font-size-xs: 10px;
  --isa-font-size-sm: 12px;
  --isa-font-size-md: 14px;
  --isa-font-size-lg: 16px;
  --isa-font-size-xl: 18px;
  --isa-font-size-xxl: 22px;
  --isa-font-size-xxxl: 28px;

  --isa-font-weight-normal: 400;
  --isa-font-weight-semibold: 500;
  --isa-font-weight-bold: bold;

  --isa-color-primary-100: #f7f6fe;
  --isa-color-primary-92: #efeefc;
  --isa-color-primary-90: #e4e3e5;
  --isa-color-primary-80: #efe9ee;
  --isa-color-primary-80-alpha: rgba(239, 233, 238, 0.4);
  --isa-color-primary-50: #542759;
  --isa-color-primary-70: #cabccc;

  --isa-color-secondary-100: #fff6f7;
  --isa-color-secondary-90: #faf4f4;
  --isa-color-secondary-50: #e22b40;

  --isa-color-danger-100: #f9c1c5;
  --isa-color-danger-70: #f70038;
  --isa-color-danger-50: #da1e13;

  --isa-color-warning-70: #fed957;
  --isa-color-warning-50: #ff9800;

  --isa-color-success-70: #78aa9c;
  --isa-color-success-60: #649485;
  --isa-color-success-50: #389884;

  --isa-color-neutral-100: #ffffff;
  --isa-color-neutral-95: #f7f7f7;
  --isa-color-neutral-90: #f4f5f7;
  --isa-color-neutral-80: #eeeeee;
  --isa-color-neutral-75: #dfdfdf;
  --isa-color-neutral-70: #dddddd;
  --isa-color-neutral-60: #d8d8d8;
  --isa-color-neutral-50: #bfbfbf;
  --isa-color-neutral-40: #acacac;
  --isa-color-neutral-30: #9f9f9e;
  --isa-color-neutral-20: #6d6d6d;
  --isa-color-neutral-15: #444444;
  --isa-color-neutral-10: #181715;
  --isa-color-neutral-0: #000000;

  --isa-main-divider-color: var(--isa-color-neutral-75);

  --isa-gap-xs: 4px;
  --isa-gap-sm: 8px;
  --isa-gap-md: 12px;
  --isa-gap-lg: 16px;
  --isa-gap-xl: 24px;
  --isa-gap-xxl: 32px;

  --isa-bar-md: 56px;

  --isa-border-radius-default: 8px;

  --isa-disabled-opacity: 0.4;

  --isa-form-gap: var(--isa-gap-sm);
  --isa-form-interaction-height: 48px;
  --isa-form-interaction-border-size: 2px;
  --isa-form-interaction-height-derivated: calc(var(--isa-form-interaction-height) - calc(var(--isa-form-interaction-border-size) * 2));
  --isa-form-interaction-padding: var(--isa-gap-md);
  --isa-form-interaction-font-family: inherit;
  --isa-form-interaction-font-size: 18px;
  --isa-form-interaction-font-weight: 500;
  --isa-form-interaction-border-radius: var(--isa-border-radius-default);
  --isa-form-interaction-color: var(--isa-color-neutral-10);
  --isa-form-interaction-border-color: var(--isa-color-primary-50);
  --isa-form-interaction-placeholder-color: var(--isa-color-neutral-30);
  --isa-form-interaction-background-color: var(--isa-color-neutral-100);
  --isa-form-interaction-disabled-color: var(--isa-color-neutral-20);
  --isa-form-interaction-disabled-border-color: var(--isa-color-neutral-70);
  --isa-form-interaction-disabled-background-color: var(--isa-color-neutral-90);
  --isa-form-interaction-disabled-opacity: var(--isa-disabled-opacity);
  --isa-form-interaction-invalid-border-color: var(--isa-color-danger-50);
  --isa-form-interaction-focus-border-color: color-mix(in srgb, shared.getFallbackColorMix() 40%, #fff);

  --isa-button-font-color: var(--isa-color-neutral-10);
  --isa-button-font-size: var(--isa-form-interaction-font-size);
  --isa-button-font-weight: bold;
  --isa-button-height: var(--isa-form-interaction-height);
  --isa-button-padding: 8px;
  --isa-button-border-size: var(--isa-form-interaction-border-size);
  --isa-button-padding-derivated: calc(var(--isa-button-padding) + var(--isa-button-border-size));
  --isa-button-border-radius: var(--isa-form-interaction-border-radius);
  --isa-button-background-color: var(--isa-color-primary-50);
  --isa-button-fab-size: var(--isa-button-height);
  --isa-button-mini-fab-size: 32px;
  --isa-button-disabled-opacity: var(--isa-form-interaction-disabled-opacity);
  --isa-button-symbol-size: 20px;

  --isa-card-padding: 12px;
  --isa-card-border-radius: 12px;
  --isa-card-nested-border-radius: calc(var(--isa-card-border-radius) - var(--isa-card-padding));
  --isa-card-box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
  --isa-card-background-color: var(--isa-color-neutral-100);

  --isa-customization-item-height: 62px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

body {
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 100dvh;
  font-family: var(--isa-font-family);
  font-size: var(--isa-font-size-md);
  line-height: var(--isa-font-size-lg);
  color: var(--isa-color-neutral-10);
}

textarea {
  width: 100%;
  max-width: 100%;
  min-height: 100px;
  border-radius: var(--isa-form-interaction-border-radius);
  border: var(--isa-form-interaction-border-size) solid var(--isa-form-interaction-border-color);
  padding: var(--isa-form-interaction-padding);
  font-family: inherit;
  font-size: inherit;
  color: inherit;
  outline: none;
  transition: 0.2s border-color ease;
  resize: none;
  &:focus {
    border-color: var(--isa-form-interaction-focus-border-color);
  }
  &.ng-invalid:not(.ng-pristine) {
    border-color: var(--isa-form-interaction-invalid-border-color);
  }
}

fieldset {
  appearance: none;
  border: none;
  outline: none;
  box-shadow: none;
  padding: 0;
  margin: 0;
}

[hidden='true'] {
  display: none !important;
}

@include mat.elevation-classes();
@include mat.app-background();

$my-primary: mat.m2-define-palette(mat.$m2-gray-palette, 500);
$my-accent: mat.m2-define-palette(mat.$m2-grey-palette, 700, 500, 900);
$my-theme: mat.m2-define-light-theme(
  (
    color: (
      primary: $my-primary,
      accent: $my-accent,
    ),
    density: 0,
  )
);
@include mat.elevation-classes();
@include mat.app-background();
@include mat.checkbox-theme($my-theme);
@include mat.radio-theme($my-theme);
@include mat.table-theme($my-theme);

input {
  &[type='number'] {
    -moz-appearance: textfield;

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

@include shared.supportsColorMix() {
  :root {
    --isa-form-interaction-focus-border-color: color-mix(in srgb, var(--isa-color-primary-50) 40%, #fff);
  }
}
